import { Grid } from "@mui/material";
import React from "react";
import { AuthUser } from "../../../contexts/AuthUserContext";
import { useUpdateApplicantSkillsMutation } from "../../../generated/graphqlHooks";
import { Applicant, Skill } from "../../../generated/graphqlTypes";
import QuestionnaireBox from "../../dashboard/QuestionnaireBox";
import FileSection from "../FilesSection";
import GeneralUserInfo from "../GeneralUserInfo";
import CertificationsSection from "./CertificationsSection";
import EducationSection from "./EducationSection";
import ExperiencesSection from "./ExperiencesSection";
import SkillsSection from "./SkillsSection";

export type ApplicantProfileProps = {
  user: AuthUser;
  applicant: Applicant;
  questionnaireProgress: number;
  allSkills: Skill[];
};

const ApplicantProfile: React.FC<ApplicantProfileProps> = ({
  user,
  applicant,
  allSkills,
  questionnaireProgress
}) => {
  const isCurrentUser = user.uid == applicant._id;
  const [updateSkills, { loading: loadingUpdate }] = useUpdateApplicantSkillsMutation();

  const handleSkillsUpdate = (skills: string[]) => {
    return updateSkills({
      variables: {
        skills
      }
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={4} xs={12}>
        <GeneralUserInfo
          info={applicant}
          emailVerified={user.emailVerified}
          questionnaireResultProgress={questionnaireProgress}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        {isCurrentUser && questionnaireProgress !== 100 && (
          <QuestionnaireBox progressValue={questionnaireProgress} />
        )}
        <FileSection isCurrentUser={isCurrentUser} resumeUrl={applicant.resumeURL} />
        <ExperiencesSection experiences={applicant.experience} isCurrentUser={isCurrentUser} />
        {isCurrentUser && (
          <EducationSection
            education={(applicant.education || []).slice().reverse()}
            isCurrentUser={isCurrentUser}
          />
        )}
        <CertificationsSection
          certifications={applicant.certifications || []}
          isCurrentUser={isCurrentUser}
        />
        <SkillsSection
          skills={applicant.skills}
          allSkills={allSkills}
          isCurrentUser={isCurrentUser}
          updateSkills={handleSkillsUpdate}
          loadingUpdate={loadingUpdate}
        />
      </Grid>
    </Grid>
  );
};

export default ApplicantProfile;
