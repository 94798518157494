import React, { useEffect, useState } from "react";
import { APPLICANT_ROLE } from "../../constants/general";
import { Applicant, Recruiter } from "../../generated/graphqlTypes";
import { useUser } from "../../hooks/authHooks";
import { useModalState } from "../../hooks/dialogHooks";
import { useIndustryPositions } from "../../hooks/positionHooks";
import { useQueryParams } from "../../hooks/routeHooks";
import JobPreferencesModal from "./applicant/JobPreferencesModal";
import UserAboutModal from "./UserAboutModal";
import UserIntro from "./UserIntro";
import UserIntroModal from "./UserIntroModal";

const showJobPreferences = "showJobPreferences";

interface GeneralUserInfoProps {
  info: Applicant | Recruiter;
  questionnaireResultProgress: number;
  emailVerified?: boolean;
}

const GeneralUserInfo: React.FC<GeneralUserInfoProps> = ({
  info,
  questionnaireResultProgress,
  emailVerified
}) => {
  const queryParams = useQueryParams();
  const { firstName, lastName, summary, avatarURL } = info;
  const user = useUser();
  const [openIntro, setOpenIntro] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const [jobPrefOpen, openJobPref, closeJobPref] = useModalState();
  const { data: positionsData, loading: loadingPositions } = useIndustryPositions(
    info.industryId,
    !("jobPreferences" in info)
  );
  const location = "Toronto, Ontario"; // TODO: LOCATION
  const modalIntroProps =
    user.uid === info._id ? { setEditModal: setOpenIntro, setOpenAbout, openJobPref } : {};
  const showAbout = user.uid === info._id && user.role === APPLICANT_ROLE;

  useEffect(() => {
    if (queryParams.get(showJobPreferences) === "true" && user.uid === info._id) {
      openJobPref();
    }
  }, []); // eslint-disable-line

  return (
    <>
      <UserIntro
        avatarURL={avatarURL}
        title={`${firstName} ${lastName}`}
        location={location}
        about={summary || (showAbout ? null : undefined)}
        jobPreferences={"jobPreferences" in info && info.jobPreferences}
        positions={positionsData?.positions}
        email={info.email}
        questionnaireResultProgress={questionnaireResultProgress}
        emailVerified={emailVerified}
        {...modalIntroProps}
      />
      <UserIntroModal defaultValues={info} open={openIntro} setOpen={setOpenIntro} />
      <UserAboutModal defaultValues={info} open={openAbout} setOpen={setOpenAbout} />
      {"jobPreferences" in info && (
        <JobPreferencesModal
          defaultJobPreferences={info.jobPreferences}
          open={jobPrefOpen}
          onClose={closeJobPref}
          positions={positionsData?.positions}
          loadingPositions={loadingPositions}
        />
      )}
    </>
  );
};

export default GeneralUserInfo;
