import { EditOutlined, LocationOnOutlined, MailOutline } from "@mui/icons-material";
import { Avatar, Chip, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { findSelectedOptions, makeStyles } from "@placehires/react-component-library";
import React from "react";
import { JobPreferences, Position } from "../../generated/graphqlTypes";
import BoxLayout, { BOX_LAYOUT_BORDER_RADIUS } from "../BoxLayout";
import SectionWithHoverEdit from "../SectionWithHoverEdit";

export type AvatarIntroProps = {
  avatarURL: string;
  variant?: "rounded" | "square"; // Defaults to circle
  setEditModal?: (open: boolean) => void;
  setOpenAbout?: (open: boolean) => void;
  openJobPref?: () => void;
  title?: string;
  location?: string;
  about?: string;
  email?: string;
  jobPreferences?: JobPreferences;
  questionnaireResultProgress?: number; // Only needed for jobPreferences
  emailVerified?: boolean;
  positions?: Position[];
};

const UserIntro: React.FC<AvatarIntroProps> = ({
  avatarURL,
  variant,
  setEditModal,
  title,
  location,
  about,
  setOpenAbout,
  jobPreferences,
  openJobPref,
  email,
  positions,
  questionnaireResultProgress,
  emailVerified
}) => {
  const { classes } = useStyles();
  const canEditJobPreferences = questionnaireResultProgress === 100 && emailVerified;

  const handleSeekingJobTooltip = () => {
    if (!openJobPref || canEditJobPreferences) {
      return "";
    }

    return questionnaireResultProgress !== 100
      ? "Complete the questionnaire first before updating job preferences"
      : "Verify your email address before updating job preferences";
  };

  return (
    <BoxLayout className={classes.paper} withoutMargin>
      <div className={classes.contentWrapper}>
        <IconButton
          sx={{
            position: "absolute",
            right: "-1rem",
            top: "-1rem",
            display: setEditModal ? "block" : "none"
          }}
          onClick={() => setEditModal(true)}
        >
          <EditOutlined sx={{ color: "primary.contrastText" }} />
        </IconButton>
        <Avatar className={classes.avatar} src={avatarURL} variant={variant} />
        <div className={classes.infoWrapper}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={about !== undefined ? 4 : 0}
          >
            <LocationOnOutlined fontSize="small" sx={{ mr: 0.5, color: "text.secondary" }} />
            <Typography>{location}</Typography>
          </Box>
          {about !== undefined && (
            <SectionWithHoverEdit
              onIconClick={() => {
                setOpenAbout(true);
              }}
              hideEdit={!setOpenAbout}
              hoverIconClassName={classes.editIcon}
            >
              <Typography variant="h4" color="primary" gutterBottom>
                About
              </Typography>
            </SectionWithHoverEdit>
          )}
          <Typography>{about || ""}</Typography>
          {jobPreferences && (
            <>
              <Divider sx={{ bgcolor: "secondary.main", my: 4 }} />

              <SectionWithHoverEdit
                onIconClick={openJobPref}
                hideEdit={!openJobPref || !canEditJobPreferences}
                hoverIconClassName={classes.editIcon}
              >
                <Tooltip title={handleSeekingJobTooltip()}>
                  <Typography variant="h4" color="primary" mb={jobPreferences?.seeking ? 2 : 3}>
                    {jobPreferences?.seeking ? "Currently Seeking" : "Not Seeking"}
                  </Typography>
                </Tooltip>
              </SectionWithHoverEdit>

              {jobPreferences?.seeking && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                  mb={3}
                >
                  {findSelectedOptions(jobPreferences?.positionsIds, positions, "_id").map(
                    (pos) => (
                      <Chip variant="outlined" label={pos.name} key={pos._id} sx={{ m: 0.5 }} />
                    )
                  )}
                </Box>
              )}
            </>
          )}

          {email && (
            <>
              <Divider sx={{ bgcolor: "secondary.main", my: 0.5 }} />
              <Box display="flex" justifyContent="center" alignItems="center">
                <MailOutline fontSize="small" sx={{ mr: 1, color: "text.secondary" }} />
                <Typography>{email.toLowerCase()}</Typography>
              </Box>
            </>
          )}
          {/*Phone number*/}
          {/*<Box display="flex" justifyContent="center" alignItems="center" mb={4}>*/}
          {/*  <CallOutlined fontSize="small" sx={{ mr: "0.5rem", color: "text.secondary" }} />*/}
          {/*  <Typography>+1 234 476890 90</Typography>*/}
          {/*</Box>*/}
        </div>
      </div>
    </BoxLayout>
  );
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    position: "relative",
    zIndex: 1,
    ":after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: 115,
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: `${BOX_LAYOUT_BORDER_RADIUS}px ${BOX_LAYOUT_BORDER_RADIUS}px 0 0`,
      zIndex: -1
    }
  },
  contentWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%"
  },
  avatar: {
    position: "relative",
    cursor: "pointer",
    width: 120,
    height: 120,
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)"
  },
  infoWrapper: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  editIcon: {
    position: "absolute",
    right: -11
  }
}));

export default UserIntro;
